import React from "react";
import "./App.css";
import { TokenDisplay } from "./pages/TokenDisplay";


function App() {
  return (
        <TokenDisplay />
  );
}

export default App;
