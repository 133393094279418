//@ts-nocheck
/**
 * React Components import
 */
import React, { Component } from "react";
/**
 * External components import
 */
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//@ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/**
 * local constant variables
 */
import styles from "./styles";

const VerticalCarouselWidget = (props: any) => {
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     window.dispatchEvent(new Event("resize"));
  //   }, 150);
  // }, []);

  const { carouselData, slidesToShow, slidesToScroll, carouselSpeed, bgColor } =
    props;
  // console.log(`slidesToScroll`, slidesToScroll);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    vertical: true,
    verticalSwiping: true,
    autoplaySpeed: carouselSpeed,
    autoplay: true,
    arrows: false,

    // nextArrow: <nextArrow color="primary" />,
    // prevArrow: <previousArrow color="primary" />,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: slidesToShow,
    //         slidesToScroll: slidesToScroll
    //       }
    //     },
    //     {
    //       breakpoint: 600,
    //       settings: {
    //         slidesToShow: slidesToShow > 4 ? 4 : slidesToShow,
    //         slidesToScroll: 2
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: slidesToShow > 3 ? 3 : slidesToShow,
    //         slidesToScroll: 1
    //       }
    //     },
    //     {
    //       breakpoint: 360,
    //       settings: {
    //         slidesToShow: slidesToShow > 2 ? 2 : slidesToShow,
    //         slidesToScroll: 1
    //       }
    //     }
    //   ]
  };
  return <Slider {...settings}>{carouselData}</Slider>;
};

export default withStyles(styles)(VerticalCarouselWidget);
